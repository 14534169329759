import { CommodityWithColor } from "components/models";
import { getAllCommodities } from "features/opportunities/components";
import { CommodityColor, IdNameWithSequence, OpportunityType, Reference } from "features/opportunities/models";

export const mergeCommodityWithColor = (
  commodities: IdNameWithSequence[],
  commodityColors: CommodityColor[]
): CommodityWithColor[] => {
  const commoditiesWithColor: CommodityWithColor[] = [];

  for (const commodity of commodities) {
    const commodityColor = commodityColors.find((color) => color.commodityId === commodity.id);
    const isDuplicate = commoditiesWithColor.some(
      (c) => c.id === commodity.id && c.color === commodityColor?.commodityHexColor
    );

    if (commodityColor && !isDuplicate) {
      commoditiesWithColor.push({
        id: commodity.id,
        name: commodity.name,
        color: commodityColor.commodityHexColor,
      });
    }
  }

  return commoditiesWithColor;
};

export const getFilteredCommoditiesWithColor = (referenceData: Reference, searchResult: OpportunityType[]) => {
  const searchResultByCommodities = searchResult.map((e) => e.primaryCommodityId);
  const flattenedCommodities = getAllCommodities(referenceData.commodityGroups);
  const filteredCommoditites = flattenedCommodities.filter((e: IdNameWithSequence) =>
    searchResultByCommodities.includes(e.id)
  );
  filteredCommoditites.sort((a, b) => (a.name < b.name ? -1 : 1));
  return mergeCommodityWithColor(filteredCommoditites, referenceData.commodityColors);
};
