import { ReactNode, createContext, useContext, useMemo, useState, useEffect } from "react";

import { RTXfootprintsConfig, Reference } from "features/opportunities/models";
import { getReferenceData } from "features/opportunities/services";

export type ReferenceDataContextType = {
  referenceData: Reference;
  setReferenceData: (data: Reference) => void;
  rtxfootprintsConfigSettings: Record<RTXfootprintsConfig["settingName"], RTXfootprintsConfig["settingValue"]>;
};

export const ReferenceDataContext = createContext<ReferenceDataContextType | null>(null);

export const useReferenceDataContext = () => {
  return useContext(ReferenceDataContext) as ReferenceDataContextType;
};

interface Props {
  children: ReactNode;
}

export function ReferenceDataProvider({ children }: Props) {
  const [referenceData, setReferenceData] = useState<Reference>({} as Reference);

  useEffect(() => {
    getReferenceData().then((response) => setReferenceData(response.data));
  }, []);

  const rtxfootprintsConfigSettings = useMemo(() => {
    const config: Record<RTXfootprintsConfig["settingName"], RTXfootprintsConfig["settingValue"]> = {};
    referenceData?.rtXfootprintsConfigs?.forEach(
      ({ settingName, settingValue }) => (config[settingName] = settingValue)
    );
    return config;
  }, [referenceData]);

  const contextValue = {
    referenceData,
    setReferenceData,
    rtxfootprintsConfigSettings,
  };
  return <ReferenceDataContext.Provider value={contextValue}>{children}</ReferenceDataContext.Provider>;
}
